import { Button, Grid, Typography } from "@mui/material";
import React from "react";
import "./../styles/about.page.scss";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import SlideImage from "./imageSlider.component";
import { useNavigate } from "react-router-dom";

function MinistrySubPage() {
  const navigate = useNavigate();
  const pathName = window?.location?.pathname || "";
  console.log(pathName);
  const data = {
    title: "",
    image: [],
    description: "",
    timings: "",
    button: "",
  };
  switch (pathName) {
    case "/worship-service": {
      data.title = "Worship Service";
      data.image = ["./assets/worship.png"];
      data.description = (
        <p>
          Our true worship is focused on the values and treasures given by God in all things.
          As Bible says, “the hour is coming and is now here when the
          true worshippers will worship the father in spirit and truth.” We
          believe God is spirit and worship God in truth and spirit.
          <br />
          <br />
          We value integrity in our worship lifestyle, desiring for our lives to
          match the lyrics we sing. We love accommodative in our worship
          environments, a way of seeking to create an open path for every person
          of every age and background to meet with God with Malayalam worship
          and English worship. We value cultural connection in our worship
          styles, appreciating that sounds and forms of music provide a “bridge
          for the heart” for different generations and people groups. And we
          also value Kingdom expectation in our approach to worship team,
          believing that as we respond to God in song, His Spirit is with us to
          heal, deliver, and set us free.
          <br /> <br />
          <i>Faith Christian Assembly Welcomes you to our worship</i>
        </p>
      );
      data.timings = `Every Sunday from 3.00pm to 5.00pm English worship: 5.00pm to 6.30pm`;
      break;
    }
    case "/youth-program": {
      data.title = "Youth Program";
      data.image = ["./assets/youth_ministry.jpg"];
      data.description = (
        <p>
          Faith Christian assembly is fully focused on youth
          personal development and growth. the church believes that investing in today's
          youth ministry is necessary for the expansion of god’s Kingdom.
        </p>
      );
      data.timings = ``;
      break;
    }
    case "/bible-study": {
      data.title = "Systematic Bible Study";
      data.image = ["./assets/biblestudy.jpg"];
      data.description = (
        <p>
          Bible study is simply the analysis of the Bible which is necessary to encourage and
          strengthen our day-to-day spiritual life. The weekly Bible study is taught by different
          pastors and professors who are filled with Holy Spirit. The systematic Bible study covers various
          topics in the Bible which is an upliftment to our Christian life and encouragement towards our spiritual life.
          The church gives importance to an individual's spiritual growth which is why this Bible study
          helps participants with their relationship with God and to study the word of God.
        </p>
      );
      data.timings = `Every Sunday from 2.30pm to 3.00pm`;
      break;
    }
    case "/sunday-school": {
      data.title = "Sunday School";
      data.image = ["./assets/ss1.jpg", "./assets/ss2.jpg"];
      data.description = (
        <p>
          The fear of the Lord is the beginning of wisdom and the knowledge of the holy one is understanding.
          The Sunday school at Faith Christian Assembly gives children of all ages an opportunity to learn
          about God, the Bible, the Holy Spirit, culture, and the values of the Christian life. We focus on
          children's understanding of the purpose of God in their life. We have four different Sunday school
          classes based on the children’s age.

        </p>
      );
      data.timings = `Every Sunday from 1.30pm to 2.30pm`;
      break;
    }
    case "/ladies-meeting": {
      data.title = "Ladies Meeting";
      data.image = ["./assets/ladiesmeeting.jpg"];
      data.description = (
        <p>
          Lady's meeting at Faith Christian Assembly focuses on empowering the women through the word of
          God with weekly prayer meetings, support groups, women networks, and chain prayers.
          It is a great opportunity to share personal testimonies and blessings that God has done in their lives.
          Our lady's meeting is every second Friday of each month between 6:00 PM to 8:00 PM.
        </p>
      );
      data.timings = `every second Friday of each month between 6:00 PM to 8:00 PM.`;
      break;
    }
    case "/cottage-meeting": {
      data.title = "Cottage Meeting";
      data.image = ["./assets/cottage.jpg"];
      data.description = (
        <p>
          A cottage meeting is an event for spiritual enrichment for the families, intercessory prayers,
          and fellowship with one another. Our cottage meeting is an opportunity to gather as a small group
          in God's presence for the true purpose of praying for special
          needs for the family and the church.
          Our cottage meeting is every Saturday from 7:00 PM to 8:00 PM.
        </p>
      );
      data.timings = `Every Saturday from 7:00 PM to 8:00 PM`;
      break;
    }
    case "/special-event": {
      data.title = "Special Events";
      data.image = [
        "./assets/vbs.jpeg",
        "./assets/vbs-2.jpeg",
        "./assets/outing-1.jpeg",
        "./assets/game-1.jpeg",
      ];
      data.description = (
        <p>
          Special events at faith Christian assemblies include:
          <br/>
          <br />
          Outing – picnics and vacation trips.
          <br />
          Barbeque – Fun game and refreshments
          <br />
          VBS
          <br />
          Youth anniversary
          <br />
          Bible Camps
          <br />
          Special meetings - family seminars, webinars, conventions, and celebration prayer meetings
          <br />
          webinars
        </p>
      );
      data.timings = ``;
      break;
    }
    case "/outreach-ministries": {
      data.title = "Outreach Ministries";
      data.image = ["./assets/outreach.jpg"];
      data.description = (
        <p>
          Faith Christian assemblies’ outreach ministries give an involvement of our 
          church members to reach people 
          around us and share the love of Christ with the unknown communities around us.
          <br />
          <br />
          Our outreach ministries also include the ministries with a homeless shelter 
          to provide support and personal evangelism, and to help them overcome their brokenness.
          <br />
          <br />
          Our outreach ministries also have Bible 
          distribution during holiday seasons and track distribution every week.
        </p>
      );
      data.timings = ``;
      break;
    }
    case "/home-visit": {
      data.title = "Home Visits";
      data.image = ["./assets/house_visit.jpg"];
      data.description = (
        <p>
         Home visits help the church to understand 
         the needs of our church members as well as other 
         community members. it is an important part of our church ministry. 
         It helps to identify the struggles and the situations the church members
          are going through and it's a great opportunity to provide the best support.
        </p>
      );
      data.timings = ``;
      break;
    }
    case "/newcomer-support": {
      data.title = "Newcomer Support";
      data.image = ["./assets/newbie.jpg"];
      data.description = (
        <p>
          The church aims to help and support newcomers, especially immigrants, new members, and visiting members. 
          It is our way of Identifying people by giving them love and support to help the growth of our church.
          <br />
          <br />
          Our church coordinators will help the newcomers to find accommodation and Government support, 
          fulfill personal needs, and provide travel assistance. if you are a newcomer or planning to 
          come to our church please press the I am new button below to 
          contact us using the details given and we welcome you to Faith Christian Assembly.
        </p>
      );
      data.timings = ``;
      data.button = "I am New";
      break;
    }

    default: {
      //   navigate("/404");
      //   window.location.reload();
    }
  }
  return (
    <section className="about">
      <Typography component="h2" variant="h2" className="sectionTitle">
        {data.title}
      </Typography>

      <Grid container spacing={2}>
        <Grid item xs={12} md={5} textAlign="center">
          <SlideImage ssImages={data.image} height="420px" />
        </Grid>
        <Grid item xs={12} md={7}>
          <Typography
            variant="body"
            className="content"
            sx={{ textAlign: "justify" }}
          >
            {data.description}
          </Typography>
          {data.timings ? (
            <div className="customCard text-center">
              <Typography variant="h6">Time</Typography>
              <Typography variant="body1">{data.timings}</Typography>
            </div>
          ) : null}
          {data.button ? (
            <div className="text-center">
              <Button
                variant="contained"
                style={{ backgroundColor: "#309EB6" }}
                onClick={() => navigate("../iamnew")}
              >
                {data.button}
              </Button>
            </div>
          ) : null}
        </Grid>
      </Grid>
    </section>
    // </Container>
  );
}

export default MinistrySubPage;
