import { Box, Button, Container, Typography } from "@mui/material";
import React from "react";

export default function FirstSection() {
  const data = "Every Sunday from 3.00pm to 5.00pm English worship: 5.00pm to 6.30pm";
  const openInNewTab = (url) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };

  return (
    <Container className="firstSection">
      <div className="firstSection_text">
        <Typography component="h1" variant="h3">
          GLORIFYING GOD TOGETHER
        </Typography>
        <Typography variant="h6">
          A PLACE FOR PRAISE AND PRAYER.
        </Typography>
      </div>

      <Box
        className="firstSection_Box"
        sx={{
          display: { md: "flex", sm: "inline-block" },
          justifyContent: "space-evenly", flexDirection: "column", alignItems:"center"
        }}
      >
        <Button
          type="menu"
          variant="contained"
          color="primary"
          onClick={() => openInNewTab("https://www.facebook.com/FCAedmontonab")}
          sx={{width:"max-content"}}
        >
          JOIN WITH US ON SUNDAY
        </Button>
        <div className="customCard text-center">
          <Typography variant="h6">Time</Typography>
          <Typography variant="body1">{data}</Typography>
        </div>
      </Box>
    </Container>
  );
}
