import { Container, Grid, Typography } from "@mui/material";
import React from "react";

function WelcomePage() {
  return (
    <section className="welcomePage greySection">
      {/* className="welcomePage" */}
      <Container maxWidth="xl" fixed={true}>
        <Typography component="h2" variant="h2" className="sectionTitle">
          Welcome to Faith Christian Assembly, Edmonton
        </Typography>
        <Grid container spacing={3} className="welcomePage_Contents">
          <Grid item xs={12} sm={12} md={7} columns={{ xs: 12, sm: 8, md: 12 }}>
            <Typography
              paragraph
              variant="h6"
              className="fontGeorgia welcomeSpeech"
              sx={{ letterSpacing: "0.025em" }}
            >
              Faith Christian Assembly Edmonton is based on the fundamental truth taught in the Bible. Therefore, all our beliefs, teaching, and ministry are rooted in the core value of Biblical Doctrines. We are rooted in God's love and committed to serving the city of Edmonton and the Indian community. It was never God's heart for us to do this life alone.
              <br />
              We are a family Pentecostal church and faith Christian believes that the church is in the body of Christ, the habitation of God through the spirit with divine appointments to fulfill its Great Commission.<br />
              We welcome you for have fellowship with us.
              <br />

            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={5}>
            <img src="../assets/IMG-20240504-WA0047.png" alt="Pastor_Picture"
              style={{ width: "50%" }}
              loading="lazy" />
            <Typography
              paragraph
              variant="h6"
              className="fontDancing welcomeSpeech"
              sx={{ letterSpacing: "0.025em" }}
            > -Pr. Maneesh Thomas</Typography>
          </Grid>
        </Grid>
      </Container>
    </section>
  );
}

export default WelcomePage;
