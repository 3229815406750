import React from "react";
import ActivityComponent from "../components/Activity.component";

function MinistriesPage() {
  return (
    <section>
        <ActivityComponent/>
    </section>
  );
}

export default MinistriesPage;
