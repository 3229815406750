import React from "react";
import { Carousel } from "react-responsive-carousel";

export default function SlideImage({ ssImages = [], title = "" }) {
  return (
    <Carousel
      autoPlay={true}
      showIndicators={false}
      showThumbs={false}
      showArrows={false}
      showStatus={false}
      transitionTime={0.3}
      verticalSwipe={"natural"}
      axis={"horizontal"}
      infiniteLoop={true}
    >
      {ssImages.map((item, i) => (
        <img src={item} alt="Slider"  key={`${Math.random()}`} height= "350px" width="auto" style={{objectFit: "cover"}} />
      ))}
    </Carousel>
  );
}
