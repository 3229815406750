import { Grid, Typography } from "@mui/material";
import { Container } from "@mui/system";
import React from "react";
import SlideImage from "../components/imageSlider.component";
import "./../styles/events.page.scss";

function EventsPage() {
  return (
    <section className="about">
      <Container maxWidth="xl" fixed={true}>
        <Typography component="h2" variant="h2" className="sectionTitle">
          Our Service Timings
        </Typography>
        <div>
          <Grid container spacing={4}>
            <Grid item xs={12} md={6}>
              <div className="customCard">
                <h4>Sunday Service</h4>
                <Typography variant="body1" className="content">
                  Sunday School : 1.30pm to 2.30pm
                </Typography>
                <Typography variant="body1" className="content">
                  Bible Study : 2.30pm to 3.00pm
                </Typography>
                <Typography variant="body1" className="content">
                  Worship : 3.00pm to 5.00pm
                </Typography>
                <Typography variant="body1" className="content">
                  English Worship : 5.00pm to 6.30pm
                </Typography>
                <h4>Cottage Meeting</h4>

                <Typography variant="body1" className="content">
                  Every Saturday : 7.00pm to 8.30pm
                </Typography>
                <h4>Ladies Meeting</h4>

              <Typography variant="body1" className="content">
                Every Second Friday :
                6.00pm to 8.00pm
              </Typography>
            </div>
            </Grid>
            <Grid item xs={12} md={6}>
              {/* <img src="./assets/event.jpg" alt="event" /> */}
              {
                [
                  {images: [
                    "./assets/event_1.jpeg",
                    "./assets/event_2.jpeg",
                    "./assets/event_3.jpeg",
                    "./assets/event_4.jpeg",
                    "./assets/event_5.jpeg",
                    "./assets/event_6.jpeg",
                    "./assets/event_7.jpeg",
                    "./assets/event_8.jpeg",
                    "./assets/event_9.jpeg",

                  ]}
                ].map(({images=[]})=> (<SlideImage ssImages={images} />))
              }
            </Grid>

            {/* <img src="./assets/event.jpg" alt="event" /> */}
            {
              ["./assets/event_1.jpeg", "./assets/event_2.jpeg", "./assets/event_3.jpeg", "./assets/event_4.jpeg", "./assets/event_5.jpeg", "./assets/event_6.jpeg", "./assets/event_7.jpeg", "./assets/event_8.jpeg", "./assets/event_9.jpeg",
            ].map((item, index) => {
              <Grid item xs={12} md={6} key={`${index}_${Math.random()}`}>
                <SlideImage ssImages={item} />
              </Grid>
})}
          </Grid>
        </div>
      </Container>
    </section>
  );
}

export default EventsPage;
