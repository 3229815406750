import React from "react";
import FacebookIcon from "@mui/icons-material/Facebook";
import YouTubeIcon from "@mui/icons-material/YouTube";
import CallIcon from "@mui/icons-material/Call";
import { IconButton, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Container } from "@mui/system";

function MiniContact() {
  const navigate = useNavigate();
  const openInNewTab = url => {
    window.open(url, '_blank', 'noopener,noreferrer');
  };
  return (
    <Container maxWidth="xl">
      <div className="minContact">
        <div>
          {/* <span>Watch On</span> */}
          <Typography component="span" variant="subtitle1">
            Watch Us On
          </Typography>
          <IconButton color="primary" size="large" component="h2" onClick={()=>openInNewTab("https://www.facebook.com/FCAedmontonab")}>
            <FacebookIcon />
          </IconButton>
        </div>
        <div>
          <Typography component="span" variant="subtitle1">
            Contact
          </Typography>
          <IconButton
            size="large"
            component="h2"
            onClick={() =>
                navigate("../iamnew")
            }
          >
            <CallIcon />
          </IconButton>
        </div>
        <div>
          <Typography component="span" variant="subtitle1">
            Videos
          </Typography>
          <IconButton style={{color: "#f00"}} size="large" component="h2" onClick={()=>openInNewTab("https://www.youtube.com/channel/UC1NFLf4jR1p8QbG0n4oUDFg")} >
            <YouTubeIcon />
          </IconButton>
        </div>

        {/* <div>
          <IconButton color="primary" size="large" component="h2">
            <CallIcon />
          </IconButton>
          <Typography component="span" variant="subtitle2">
            +1 234 56789
          </Typography>
        </div>
        <div>
          <IconButton color="primary" size="large" component="h2">
            <LocationOnIcon />
          </IconButton>
          <Typography component="span" variant="subtitle2">
            Lorem IPsum
          </Typography>
        </div> */}
      </div>
    </Container>
  );
}

export default MiniContact;
