
import React from "react";
import ActivityComponent from "../components/Activity.component";
import FirstSection from "../components/FirstSection.component";
import MiniContact from "../components/MiniContact.component";
import WelcomePage from "../components/welcomePage.component";
import video from "../assets/underwater.mp4"
// import video from "../assets/under_water.mp4"
import "./../styles/home.page.scss";

function IndexPage() {
  return (
    <div className="home">
      <VideoSection />
      {/* <Container maxWidth="xl" fixed={true}> */}
        <MiniContact />
        <WelcomePage />
        {/* <ServiceComponent/> */}
        <ActivityComponent />
      {/* </Container> */}
    </div>
  );
}

function VideoSection() {
  return (
    <header className="video-wrapper">
      <video
        src={video}
        autoPlay
        loop
        playsInline
        muted
      />
      <div className="viewport-header">
        <FirstSection />
      </div>
    </header>
  );
}

export default IndexPage;
