import "./styles/App.scss";
import React, { Suspense, lazy } from "react";
import { BrowserRouter, Outlet, Route, Routes } from "react-router-dom";
import Loader from "./pages/Loader.page";
import IndexPage from "./pages/home.page";
import MinistriesPage from "./pages/ministries.page";
import EventsPage from "./pages/events.page";
import ContactPage from "./pages/contact.page";
import MinistrySubPage from "./components/ministriesInner.component";
import AddressFooter from "./components/addressFooter.component";

const Header = lazy(() => import("./components/Header.component"));
const Footer = lazy(() => import("./components/Footer.component"));
const PageNotFound = lazy(() => import("./pages/PageNotFound.page"));
const AboutPage = lazy(() => import("./pages/about.page"));
export default function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route
            path="/home"
            element={
              <Suspense fallback={<Loader />}>
                <Home />
              </Suspense>
            }
          />
          <Route
            path="/about"
            element={
              <Suspense fallback={<Loader />}>
                <AboutPage />
              </Suspense>
            }
          />
          <Route
            path="/events"
            element={
              <Suspense fallback={<Loader />}>
                <EventsPage />
              </Suspense>
            }
          />
          <Route
            path="/ministries"
            element={
              <Suspense fallback={<Loader />}>
                <MinistriesPage />
              </Suspense>
            }
          />
          <Route
            path="/iamnew"
            element={
              <Suspense fallback={<Loader />}>
                <ContactPage />
              </Suspense>
            }
          />
          <Route
            path="/worship-service"
            element={
              <Suspense fallback={<Loader />}>
                <MinistrySubPage />
              </Suspense>
            }
          />
          <Route
            path="/youth-program"
            element={
              <Suspense fallback={<Loader />}>
                <MinistrySubPage />
              </Suspense>
            }
          />
          <Route
            path="/bible-study"
            element={
              <Suspense fallback={<Loader />}>
                <MinistrySubPage />
              </Suspense>
            }
          />
          <Route
            path="/sunday-school"
            element={
              <Suspense fallback={<Loader />}>
                <MinistrySubPage />
              </Suspense>
            }
          />
          <Route
            path="/ladies-meeting"
            element={
              <Suspense fallback={<Loader />}>
                <MinistrySubPage />
              </Suspense>
            }
          />
          <Route
            path="/cottage-meeting"
            element={
              <Suspense fallback={<Loader />}>
                <MinistrySubPage />
              </Suspense>
            }
          />
          <Route
            path="/special-event"
            element={
              <Suspense fallback={<Loader />}>
                <MinistrySubPage />
              </Suspense>
            }
          />
          <Route
            path="/outreach-ministries"
            element={
              <Suspense fallback={<Loader />}>
                <MinistrySubPage />
              </Suspense>
            }
          />
          <Route
            path="/home-visit"
            element={
              <Suspense fallback={<Loader />}>
                <MinistrySubPage />
              </Suspense>
            }
          />
          <Route
            path="/newcomer-support"
            element={
              <Suspense fallback={<Loader />}>
                <MinistrySubPage />
              </Suspense>
            }
          />
          <Route
            index
            element={
              <Suspense fallback={<Loader />}>
                <Home />
              </Suspense>
            }
          />

          <Route
            path="*"
            element={
              <Suspense fallback={<Loader />}>
                <Page404 />
              </Suspense>
            }
          />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

function Layout() {
  return (
    <div className="">
      <Suspense fallback={<Loader />}>
        <Header />
        <div className="content top">
          <Outlet />
        </div>
        <AddressFooter />
        <Footer />
      </Suspense>
    </div>
  );
}
function Home() {
  return <IndexPage />;
}
function Page404() {
  return (
    <Suspense fallback={<Loader />}>
      <PageNotFound />
    </Suspense>
  );
}
