import React from 'react'
import { Avatar, Typography } from "@mui/material";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import PhoneIcon from "@mui/icons-material/Phone";

function AddressFooter() {
  return (
    <div className="space-between addressFooter">
    <div className="logo space-between">
      <Avatar
        alt="Logo"
        src="./../assets/fca_logo.png"
        sx={{ width: { md: 95, sm: 56 }, height: { md: 95, sm: 56 } }}
        variant="square"
      />
      <Typography variant="h6">Faith Christian Assembly <br/>Edmonton</Typography>
    </div>
    <div className="iconContent">
    <LocationOnIcon />&nbsp;
      <Typography variant="body1">
        6811 92A AVE NW, Edmonton,
        <br />
        Canada AB T6B 0T7
      </Typography>
    </div>
    <div className="iconContent">
    <PhoneIcon />&nbsp;
      <Typography>
        Pr. Maneesh Thomas
        <br />
        <a href="tel:+15879365155">+1 587 936 5155</a>
      </Typography>
    </div>
  </div>
  )
}

export default AddressFooter