import { Container, Typography, Card, Grid } from "@mui/material";
import React from "react";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import { useNavigate } from "react-router-dom";
import { activityData } from "../helpers/constants.helpers";

function ActivityComponent() {
  return (
      <Container maxWidth="xl" fixed={true}>
        <Typography component="h2" variant="h2" className="sectionTitle">
          Church Ministries
        </Typography>
        <Grid container spacing={1}>
          {activityData.map((i, index) => (
            <Grid  key={Math.random() * index} item xs={12} sm={6} md={3}>
              <ActivityCard data={i} />
            </Grid>
          ))}
        </Grid>
      </Container>
  );
}
const ActivityCard = ({ data = {} }) => {
  const navigate = useNavigate();
  return (
    <Card sx={{textAlign:"center"}} onClick={()=> navigate(`${data.url}`)}>
      <CardMedia
        component="img"
        image={data.img}
        alt={data.label}
        sx={{width:'20em', height: 250}}
      />
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
          {data.label}
        </Typography>
      </CardContent>
    </Card>
  );
};
export default ActivityComponent;
