import React, { useState } from "react";
import { Button, Paper, TextField, Typography } from "@mui/material";

function FormComponent() {
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [query, setQuery] = useState("");
  const [emailErrorText, setEmailErrorText] = useState("");
  const [nameErrorText, setNameErrorText] = useState("");
  const [queryErrorText, setQueryErrorText] = useState("");

  const onSubmit = (e) => {
    e.preventDefault();
    if (!name) {
      setNameErrorText("Please enter name");
    } else {
      setNameErrorText("");
    }
    let re = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9-]{2,}[.][a-zA-Z]{2,4}$/;
    if (!email || !re.test(email)) {
      setEmailErrorText("Please enter a valid email");
    } else {
      setEmailErrorText("");
    }
    if (!query) {
      setQueryErrorText("Please enter query");
    } else {
      setQueryErrorText("");
    }
    // email option need to be implement
  };

  return (
    <Paper elevation={3} className="customForm text-center">
      <Typography component="h1" variant="h5">
        Enquiry Form
      </Typography>
      <form>
        <TextField
          variant="outlined"
          margin="normal"
          required
          type="text"
          fullWidth
          id="name"
          label="Name"
          name="name"
          autoFocus
          value={name}
          error={!!nameErrorText}
          helperText={nameErrorText}
          onChange={(e) => setName(e.target.value)}
        />
        <TextField
          variant="outlined"
          margin="normal"
          required
          type="email"
          fullWidth
          id="email"
          label="Email"
          name="email"
          // autoFocus
          value={email}
          error={!!emailErrorText}
          helperText={emailErrorText}
          onChange={(e) => setEmail(e.target.value)}
        />
        <TextField
          variant="outlined"
          margin="normal"
          required
          type="text"
          multiline
          rows={4}
          fullWidth
          id="query"
          label="Write Something"
          name="Write Something"
          // autoFocus
          value={query}
          error={!!queryErrorText}
          helperText={queryErrorText}
          onChange={(e) => setQuery(e.target.value)}
        />
        <Button
          // sx={{
          //   mx: "auto",
          //   width: 200,
          // }}
          type="submit"
          variant="contained"
          onClick={onSubmit}
          // fullWidth
          style={{ backgroundColor: "#309EB6" }}
        >
          Send Message
        </Button>
      </form>
    </Paper>
  );
}

export default FormComponent;
