import React from "react";
import "./../styles/contact.scss";
import { Container, Grid, Typography } from "@mui/material";
import FormComponent from "../components/form.component";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import EmailIcon from "@mui/icons-material/Email";
import PhoneIcon from "@mui/icons-material/Phone";

function ContactPage() {
  return (
    <section className="contact">
      <Container maxWidth="xl" fixed={true}>
        <Typography component="h2" variant="h2" className="sectionTitle">
          Contact Us
        </Typography>
        <Grid container>
          <Grid item sm={12} md={6}>
            <Grid container spacing={2}>
              <Grid item md={10} sm={12}  className="customCard">
                <div className="iconContent">
                  <LocationOnIcon className="icon" />
                  &nbsp;
                  <Typography variant="body1" className="content">
                    6811 92A AVE NW, Edmonton,
                    <br />
                    Canada AB T6B 0T7
                  </Typography>
                </div>
              </Grid>
              <Grid item md={10} sm={12}  className="customCard">
                <div className="iconContent">
                  <EmailIcon className="icon" />
                  &nbsp;
                  <Typography variant="body1" className="content">
                    <a href="mailto:fcapastorca@gmail.com">
                      fcapastorca@gmail.com
                    </a>
                  </Typography>
                </div>
              </Grid>
              <Grid item md={10} sm={12}  className="customCard">
                <div className="iconContent">
                  <PhoneIcon className="icon" />
                  &nbsp;
                  <Typography>
                    Pr. Maneesh Thomas
                    <br />
                    <a href="tel:+15879365155">+1 587 936 5155</a>
                  <br/>
                    {/* Br. Arul John
                    <br />
                    <a href="tel:+17807211885">+1 780 721 1885</a> */}
                  </Typography>
                </div>
              </Grid>
            </Grid>
          </Grid>

          <Grid item sm={12} md={6}>
            <FormComponent />
          </Grid>
        </Grid>
      </Container>
    </section>
  );
}

export default ContactPage;
