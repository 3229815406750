export const headersData = [{
        label: "Home",
        href: "/home",
    }, {
        label: "About",
        href: "/about",
    },
    {
        label: "Ministries",
        href: "/ministries",
    },

    {
        label: "Events",
        href: "/events",
    },
    {
        label: "I’m New",
        href: "/iamnew",
    },
];
export const activityData = [
    { img: "./assets/worship.png", label: 'Worship Service', url: "/worship-service" },
    { img: "./assets/youth_ministry.jpg", label: 'Youth Program', url: "/youth-program" },
    { img: "./assets/biblestudy.jpg", label: 'Systematic Bible Study', url: "/bible-study" },
    { img: "./assets/sundayschool.jpg", label: 'Sunday School', url: "/sunday-school" },
    { img: "./assets/ladiesmeeting.jpg", label: 'Ladies Meeting', url: "/ladies-meeting" },
    { img: "./assets/cottage.jpg", label: 'Cottage Meeting', url: "/cottage-meeting" },
    { img: "./assets/specialevent.png", label: 'Special Events', url: "/special-event" },
    { img: "./assets/outreach.jpg", label: 'Out reach Ministries', url: "/outreach-ministries" },
    { img: "./assets/house_visit.jpg", label: 'Home Visits', url: "/home-visit" },
    { img: "./assets/newbie.jpg", label: 'Newcomers support', url: "/newcomer-support" }
]
